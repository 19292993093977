@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Expo Arabic", "sans-serif";
}
h1 {
	font-weight: bold;
}
.hover:hover {
	transform: scale(1.1, 0.9);
}

.container {
	width: 100%;
}
.containerPadded{
	padding: 0 8%;
}

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
}


@media (max-width: 567px) {
	.hover:hover {
		transform: scale(1);
	}
	
}


.pin__arrow{
	position: absolute;
	bottom: 0;
	cursor: pointer;
	left: 0;
	right: 0;
}