.footer {
	height: 10vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	bottom: 50px;
	right: 20px;
}
.icon,
.link {
	width: 30px;
	height: 40px;
}
.icon {
	color: #0000008f;
}
.icon:hover {
	color: #00000071;
}
.link {
	text-decoration: none;
}
