.wrapper{
  box-sizing:initial;
  width: 690px;
  border-radius: 14px;
  overflow: hidden;
  transition: 0.3s;
  cursor:pointer;
  outline: 2px solid rgba(0, 0, 0, 0.189);
  outline-offset: -2px;
  display: flex;
  flex-direction: column;
}
.wrapper:hover{
  transform: scale(0.95);
  outline: 3px solid rgba(0, 0, 0, 0.3);
  outline-offset: -1px;
}
.imgWrapper {
  height: 388px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Optional: hides any overflow if the image is larger than the container */
  aspect-ratio: 16/9;
}

.imgWrapper > img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; /* Maintains aspect ratio, and covers the entire container */
}
.textWrapper{
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  align-self: flex-end;
  display: flex;
  place-items: center;
  padding: 13px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  transition: 0.3s;
}
.button:hover{
  background: rgb(213, 213, 213);
}

@media (max-width: 567px) {
  .wrapper{
    width: 100%;
  }
  .imgWrapper{
    min-height: initial;
    height: auto;
  }
  .button{
    display: none;
  }
}
