.main,
.logoDiv {
	display: flex;
	justify-content: center;
	align-items: center;
}

.main {
	height: 100vh;
	width: 100%;
	z-index: 999;
	/* position: sticky;
	top: -95vh; */
	/* background: linear-gradient(#eeffbc, #ffcfe0, #a9ccdf); */
	background: white;
	/* background-size: 300% 200%; */
	/* animation: AnimationName 2s ease-in-out infinite; */
	background-position: center;
}

@keyframes AnimationName {
	0% {
		background-size: 100% 100%;
	}
	50% {
		background-size: 300% 300%;
	}
	100% {
		background-size: 100% 100%;
	}
}
.mainLogo {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	animation: popUp 1.5s 200ms ease-in-out;
	transition: 0.5s;
	width: 150px;
	cursor:cell;
}
@keyframes popUp {
	0% {
		transform: scale(3, 0);
		opacity: 0;
	}
	40% {
		opacity: 0.5;
		transform: scale(1, 1.2);
	}
	70% {
		opacity: 1;
		transform: scale(1.1, 1.1);
	}
	100% {
		transform: scale(1);
	}
}

.section {
	height: fit-content;
	display: flex;
	flex-direction: column;
	position: relative;
}
.header {
	display: flex;
	justify-content: flex-end;
	height: 10vh;
	align-self: flex-end;
	align-items: center;
	position: sticky;
	top: 0px;
	width: 100%;
	z-index: 2;
	background: #fff; 
	box-shadow: 0 1px 2px 0px #919191;
}
.header > a {
	text-decoration: none;
	color: black;
	margin-left: 24px;
	transition: 0.1s;
	font-size: 18px;
	line-height: 24px;
}
.sectionLable {
	/* z-index: 1;
	top: 0px;
	background: #fff; 
	background-size: 120%,100%;
	position: sticky;
	font-weight: 100;
	font-size: 116px;
	line-height: 155px;
	box-shadow: 0 1px 2px 2px #fff; */
	background: #fff;
	font-weight: 100;
	font-size: 42px;
	line-height: 40px;
	text-align: center;
	letter-spacing: 2px;
	width: 100%;

}

.cards {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 80px;
	margin-bottom: 80px;
	gap: 40px;
}
.Contacts {
	position: sticky;
	z-index: 3;
	top: 100vh;
}

@media (max-width: 567px) {
	.sectionLable {
		/* display: none; */
	}
	.mainLogo{
		width: 100px;
	}
	.header {
		justify-content: space-evenly;
		background: white;
		top: 0;
		padding: 0 20px;
		padding-top: 20px;
	}
	.header > a {
		margin: 0;
	}
	.cards{
		margin-top: 60px;
		margin-bottom: 60px;
	}
}
