.SliderWrapper {
	display: flex;
	width: 99vw;
	flex-direction: column;
	padding-top: 120px;
	padding-bottom: 120px;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	overflow: hidden;
}
.SliderWrapper * {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
.sliderElements{
	display: flex;
	overflow-x: scroll;
}
.sliderElements::-webkit-scrollbar{
	display: none;
}
.SliderWrapper > h2 {
	background: #fff;
	font-weight: 100;
	font-size: 42px;
	line-height: 155px;
	box-shadow: 0 1px 2px 2px #fff;
	text-align: center;
	letter-spacing: 2px;
}
.sliderElements > img {
	width: 200px;
}
@media (max-width: 678px) {
	.SliderWrapper {
		padding-top: 64px;
		padding-bottom: 64px;
	}
	.logo {
		display: flex !important;
		align-items: center;
		justify-content: center;
		height: 150px;
		aspect-ratio: 1/1;
	}
	.logo > img {
		width: 60%;
	}
	.SliderWrapper > h2 {
		background: #fff;
		font-weight: 100;
		font-size: 30px;
		line-height: 155px;
		box-shadow: 0 1px 2px 2px #fff;
		text-align: center;
		letter-spacing: 2px;
	}
	.sliderElements > img {
		width: 160px;
	}
	
}
