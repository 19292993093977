html{
	scroll-behavior: smooth;
}
::-webkit-scrollbar{
	width: 5px;
	padding: 3px 5px;
}
::-webkit-scrollbar-track {
  background: white;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(#eeffbc, #ffcfe0, #a9ccdf);
	border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {

}


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.font-Expo {
  font-family: "Expo Arabic", 'sans-serif';
}



@font-face {
	font-family: "Expo Arabic";
	src: local("Expo Arabic"),
	url("./fonts/Expo Arabic/Expo Arabic Bold.ttf") format("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "Expo Arabic";
	src: local("Expo Arabic"),
		url("./fonts/Expo Arabic/Expo Arabic Book.ttf") format("truetype");
	font-weight: normal;
}
@font-face {
	font-family: "Expo Arabic";
	src: local("Expo Arabic"),
		url("./fonts/Expo Arabic/Expo Arabic Light.ttf") format("truetype");
	font-weight: Light;
}
@font-face {
	font-family: "Expo Arabic";
	src: local("Expo Arabic"),
		url("./fonts/Expo Arabic/Expo Arabic Medium.ttf") format("truetype");
	font-weight: Medium;
}

.reveal{
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease-in-out;
}

.reveal.active{
	transform: translateY(0);
	transition: 1s all ease-in-out;
  opacity: 1;
}
